import {Link} from "react-router-dom";

function Home() {
    return (
        <div className="Home">
            <p className="subtitle has-text-pink">
                i.am.dev
            </p>

            <p className="title">
                Jochem van Nieuwenhuijsen
            </p>
            <p className="subtitle">
                freelance php developer
            </p>
            <p>
                <Link to="/resume" className="button is-medium is-rounded mr-2 has-background-pink">resume</Link>
                <a rel="noreferrer" href="https://www.linkedin.com/in/jochemvn/" target="_blank" className="button is-medium is-rounded ml-2">linkedin</a>
            </p>
        </div>
    );
}

export default Home;