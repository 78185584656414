import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Home  from './components/Home';
import Resume  from './components/Resume';
import Navigation from "./components/Navigation";
import BgImage from "./images/bg5.jpg";

import './styles/Main.css';

function Main() {
  return (
    <div className="Main">
        <Router>
            <section className="hero is-primary is-fullheight has-background-black-matte header-image" style={{ backgroundImage: `url(${BgImage})` }}>
                <Navigation/>

                <div className="hero-body">
                    <div className="container has-text-centered">
                        <Switch>
                            <Route exact path="/">
                                <Home />
                            </Route>
                            <Route path="/resume">
                                <Resume />
                            </Route>
                        </Switch>
                    </div>
                </div>


                <div className="hero-foot">
                    <nav className="tabs">
                        <div className="container">
                            <ul>
                                <li className=""><a rel="noreferrer" target="_blank" href="https://www.github.com/jochemvn">github</a></li>
                                <li className=""><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/jochemvn">linkedin</a></li>
                                <li className=""><a rel="noreferrer" target="_blank" href="https://www.twitter.com/jochemvn">twitter</a></li>
                                <li className=""><a rel="noreferrer" target="_blank" href="https://www.instagram.com/jochemvn">instagram</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </section>
        </Router>
    </div>
  );
}

export default Main;
