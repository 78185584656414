
function Resume() {
    return (
        <div className="Resume">
            <p className="subtitle has-text-pink">
                my.cv
            </p>

            <p className="title">
                Resume
            </p>
            <p className="subtitle">
                checkout my resume
            </p>
            <p>
                <a rel="noreferrer" href="https://resume.io/r/lfjtDzidk" target="_blank" className="button has-background-pink is-medium is-rounded ml-2">dutch</a>
                <a rel="noreferrer" href="https://resume.io/r/ZICJsmqlR" target="_blank" className="button has-background-pink is-medium is-rounded ml-2">english</a>
            </p>
        </div>
    );
}

export default Resume;