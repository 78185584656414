import {Link} from "react-router-dom";

function Navigation() {
    return (
        <div className="Navigation">
            <div className="hero-head">
                <nav className="navbar">
                    <div className="container">
                        <div className="navbar-brand">
                                <span className="navbar-burger" data-target="navbarmenu">
                                    <span/>
                                    <span/>
                                    <span/>
                                </span>
                        </div>
                        <div id="navbarmenu" className="navbar-menu">
                            <div className="navbar-end has-text-pink">
                                <Link className="navbar-item is-outlined" to="/">home</Link>
                                <Link className="navbar-item" to="/resume">resume</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}


export default Navigation;